import React from 'react';
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { RiDeleteBin2Line } from 'react-icons/ri';

export default function Table({ columns, data, deleteData }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['_id'] },
    },
    useGlobalFilter,
  );

  // Render the UI for your table
  return (
    <div className="mt-2 flex flex-col">
      <div className="my-2 overflow-x-auto mx-4 sm:-mx-6 lg:-mx-8">
        <div className=" py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 border-2 border-teal-200 rounded-lg">
          <div className="max-h-52 overflow-y-scroll  ">
            <table {...getTableProps()} className=" min-w-full divide-y divide-gray-200 ">
              <thead className="w-full bg-gray-200 sticky top-0">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        className=" px-10 uppercase tracking-wide text-gray-700 text-xs font-bold"
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                    {/*Essa div funciona para o icone de lixeira não aparecer em cima do header */}
                    <div className=""></div>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr className="border-y-2 border-gray-200" {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className="px-2 text-center">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <button onClick={() => deleteData(row.values['_id'])} className="mt-2">
                        <RiDeleteBin2Line color="red" />
                      </button>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
