import { IMenuConstrutorArgs, Menu } from './menu';

export interface ICardapioDiarioConstrutorArgs {
  data: Date;
  menus: IMenuConstrutorArgs[];
}

export class CardapioDiario {
  private _data: Date;
  private _menus: Menu[];

  constructor({ data, menus }: ICardapioDiarioConstrutorArgs) {
    if (menus.length !== 2) {
      throw new Error('Um dia precisa de exatamente 2 menus');
    }

    const dataEInstanciaDeDate = data instanceof Date;
    if (!dataEInstanciaDeDate) {
      data = new Date(data);
    }

    this._data = data;
    this._menus = menus.map(menu => new Menu(menu));
  }

  public static copiar(dia: CardapioDiario): ICardapioDiarioConstrutorArgs {
    return {
      data: dia.data,
      menus: dia.menus.map(menu => Menu.copiar(menu)),
    };
  }

  public static colar(args: ICardapioDiarioConstrutorArgs): CardapioDiario {
    return new CardapioDiario(args);
  }

  // Getters e setters

  /**
   * Getter data
   * @return {Date}
   */
  public get data(): Date {
    return this._data;
  }

  /**
   * Getter menus
   * @return {Menu[]}
   */
  public get menus(): Menu[] {
    return this._menus;
  }

  /**
   * Setter data
   * @param {Date} value
   */
  public set data(value: Date) {
    this._data = value;
  }

  /**
   * Setter menus
   * @param {Menu[]} value
   */
  public set menus(value: Menu[]) {
    this._menus = value;
  }
}
