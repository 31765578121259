import { CardapioSemanal } from '../objects/cardapio-semanal';
import { Menu } from '../objects/menu';
import { RotulosPadraoDasPreparacoesEnum } from '../objects/sharedEnums';
import { IsoToDayWithName } from '../utils/dateManipulation.utils';
import { CellCol } from './cell-col.component';
import { BiSolidLeftArrow, BiSolidRightArrow, BiLeftArrow, BiRightArrow } from 'react-icons/bi';

interface CardapioProps {
  cardapioSemanal: CardapioSemanal;
  bancoDeAlimentos: any;
  indiceDaRefeicao: number;
  mudarSemana: (avanco: number) => void;
}

export function Cardapio({ cardapioSemanal, indiceDaRefeicao, bancoDeAlimentos, mudarSemana }: CardapioProps) {
  // Quando tivermos mais de 2 refeições no dia vamos precisar alterar essa lógica
  const rotulo = indiceDaRefeicao === 0 ? cardapioSemanal.rotuloAlmoco : cardapioSemanal.rotuloJantar;

  return (
    <div className="w-fit h-fit flex justify-center items-center bg-slate-200 border-2 border-teal-300 rounded-2xl pl-14 pt-14">
      <div className="flex flex-row space-x-1">
        <div className="w-full flex flex-col space-y-1 pr-7">
          <div className="w-full h-28 pb-8 flex flex-col  justify-center items-center">
            <span className="text-3xl font-semibold">{rotulo}</span>
            <div className="w-full flex justify-between items-center">
              <button className="w-fit h-fit" onClick={() => mudarSemana(-1)}>
                <BiSolidLeftArrow size={50} />
              </button>
              <span className="text-2xl font-semibold">
                {IsoToDayWithName(cardapioSemanal.dias[0].data.toISOString()).day}-
                {IsoToDayWithName(cardapioSemanal.dias.at(-1)!.data.toISOString()).day}
              </span>
              <button className="w-fit h-fit" onClick={() => mudarSemana(1)}>
                <BiSolidRightArrow size={50} />
              </button>
            </div>
          </div>
          {Object.values(RotulosPadraoDasPreparacoesEnum).map((section, index) => (
            <div className="w-fit h-28 text-2xl uppercase text-gray-500 font-bold flex items-center " key={index}>
              {section}
            </div>
          ))}
        </div>
        {cardapioSemanal.dias.map((cardapioDiario, index) => (
          <div className="w-40 flex flex-col items-center justify-center" key={cardapioDiario.data.toISOString()}>
            <div className="">
              <div className="w-fit text-3xl uppercase text-black font-bold">
                {IsoToDayWithName(cardapioDiario.data.toISOString()).day}
              </div>
              <div className="w-fit mb-5 text-lg uppercase text-gray-500 font-bold ">
                {IsoToDayWithName(cardapioDiario.data.toISOString()).name}
              </div>
            </div>
            <CellCol menu={cardapioDiario.menus[indiceDaRefeicao]} updateCell={() => {}} />
          </div>
        ))}
      </div>
    </div>
  );
}
