import { ReactNode } from 'react';
import { CardapioSemanal, ICardapioSemanalConstrutorArgs } from './cardapio-semanal';
import { CellCol } from '../components/cell-col.component';
import { CardapioDiario } from './cardapio-diario';
import { DataParaMes, MesParaDataEnum, MesSeguinte, PrimeiraSegundaDoMes } from '../utils/dateManipulation.utils';
import { TextosPdf } from '../components/PDF/pdf-menu';

export interface ICardapioMensalConstrutorArgs {
  nomeArquivo: string;
  versao: number;
  cardapiosSemanais?: ICardapioSemanalConstrutorArgs[];
  dataInicial?: Date;
}

export class CardapioMensal {
  private _nomeArquivo: string;
  private _versao: number;
  private _cardapiosSemanais: CardapioSemanal[];
  private _dataInicial: Date;

  constructor({ nomeArquivo, versao, cardapiosSemanais, dataInicial }: ICardapioMensalConstrutorArgs) {
    this._nomeArquivo = nomeArquivo;
    this._versao = versao;

    const hoje = new Date();
    const primeiraSegundaDoMesSeguinte = PrimeiraSegundaDoMes(DataParaMes(hoje, MesSeguinte(hoje.getMonth())));

    const dataEInstanciaDeDate = dataInicial instanceof Date;
    if (dataInicial && !dataEInstanciaDeDate) {
      dataInicial = new Date(dataInicial);
    }

    this._dataInicial = dataInicial || primeiraSegundaDoMesSeguinte;
    if (!cardapiosSemanais) {
      this._cardapiosSemanais = [];
      this._cardapiosSemanais = this.gerarSemanasVazias(this._dataInicial);
    } else {
      if (cardapiosSemanais.length !== 4) {
        throw new Error('Um cardápio mensal precisa de exatamente 4 cardápios semanais');
      }
      this._cardapiosSemanais = cardapiosSemanais.map(semana => new CardapioSemanal(semana));
    }
  }

  private gerarSemanasVazias(data?: Date): CardapioSemanal[] {
    const semanas: CardapioSemanal[] = [];
    data = data || new Date();
    // Passar horário para 12h
    data.setHours(12, 0, 0, 0);
    const umDiaEmMilisegundos = 24 * 60 * 60 * 1000;
    for (let i = 0; i < 4; i++) {
      const dias = i * 7;
      const seteDiasEmMilisegundos = dias * umDiaEmMilisegundos;
      const dataInicial = new Date(data.getTime() + seteDiasEmMilisegundos);
      semanas.push(new CardapioSemanal({ dataInicial }));
    }
    return semanas;
  }

  public static copiar(mensal: CardapioMensal): ICardapioMensalConstrutorArgs {
    return {
      nomeArquivo: mensal.nomeArquivo,
      versao: mensal.versao,
      cardapiosSemanais: mensal.cardapiosSemanais.map(semana => CardapioSemanal.copiar(semana)),
    };
  }

  public static colar(args: ICardapioMensalConstrutorArgs): CardapioMensal {
    return new CardapioMensal(args);
  }

  public getCardapioSemanal(index: number): CardapioSemanal {
    return this._cardapiosSemanais[index];
  }

  public montarColunaDiariaDaPlanilha(date: Date) {
    const dia = this.buscarCardapioDoDia(date);

    if (!dia) {
      throw new Error('Data fora do intervalo do cardápio');
    }

    return {
      [dia.menus[0].rotulo]: CellCol({ menu: dia.menus[0], updateCell: () => {} }),
      [dia.menus[1].rotulo]: CellCol({ menu: dia.menus[1], updateCell: () => {} }),
    };
  }

  public buscarCardapioDoDia(date: Date): CardapioDiario | undefined {
    // Passa date para 12h
    date.setHours(12, 0, 0, 0);
    // Quantos dias tem entre a data inicial e a data passada
    const deltaDias = Math.floor((date.getTime() - this._dataInicial.getTime()) / (24 * 60 * 60 * 1000));

    // Se a data passada é anterior a data inicial ou posterior a 28 dias após a data inicial, retorna coluna vazia
    if (deltaDias < 0 || deltaDias > 28) {
      return undefined;
    }

    // Calcula em qual semana a data passada está
    const indiceSemana = Math.floor(deltaDias / 7);

    // Pega a coluna da semana
    const semana = this._cardapiosSemanais[indiceSemana];

    // Calcula o dia da semana
    const diaIndice = deltaDias % 7;

    const dia = semana.dias[diaIndice];

    return dia;
  }

  public nomeDoMes(): string {
    return MesParaDataEnum[this._dataInicial.getMonth()];
  }

  public nomeDoAno(): string {
    return this._dataInicial.getFullYear().toString();
  }

  public gerarTextoTitulo(): string {
    return `SISTEMA DE ALIMENTAÇÃO DA UFRJ - RESTAURANTE UNIVERSITÁRIO`;
  }

  public gerarTextoSubtitulo(indice?: number): string {
    const indiceDoCardapio = indice ?? '';
    return `CARDÁPIO ${indiceDoCardapio} - ${this.nomeDoMes()} ${this.nomeDoAno()} - RU CENTRAL, CT, LETRAS, IFCS e PV`;
  }

  public gerarTextoEdicao(): string {
    return `CARDÁPIO ${this.nomeDoMes()} ${this.nomeDoAno()} Edição ${this.versao}`;
  }

  public gerarTextoAprovacao(): string {
    return `Elaborado pela equipe de Planejamento Dietético e aprovado pelas equipes SIA e Nutryenerge`;
  }

  public getTextos(indiceDaSemana?: number): TextosPdf {
    let indice = indiceDaSemana;
    // Validar se o indice existe. Temos que fazer verificações a mais porque 0 é considerado undefined, então precisamos de uma lógica para tratar isso.
    if (indice === 0 || (indice && indice > 0)) {
      indice += 1;
    }
    return {
      titulo: this.gerarTextoTitulo(),
      subtitulo: this.gerarTextoSubtitulo(indice),
      edicao: this.gerarTextoEdicao(),
      textoDeAprovacao: this.gerarTextoAprovacao(),
    };
  }

  // Getters e setters

  /**
   * Getter nomeArquivo
   * @return {string}
   */
  public get nomeArquivo(): string {
    return this._nomeArquivo;
  }

  /**
   * Getter versao
   * @return {number}
   */
  public get versao(): number {
    return this._versao;
  }

  /**
   * Getter cardapiosSemanais
   * @return {CardapioSemanal[]}
   */
  public get cardapiosSemanais(): CardapioSemanal[] {
    return this._cardapiosSemanais;
  }

  /**
   * Setter nomeArquivo
   * @param {string} value
   */
  public set nomeArquivo(value: string) {
    this._nomeArquivo = value;
  }

  /**
   * Setter versao
   * @param {number} value
   */
  public set versao(value: number) {
    this._versao = value;
  }

  /**
   * Setter cardapiosSemanais
   * @param {CardapioSemanal[]} value
   */
  public set cardapiosSemanais(value: CardapioSemanal[]) {
    this._cardapiosSemanais = value;
  }
}
