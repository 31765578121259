import React, { useEffect } from 'react';
import { CrudFormComponent } from '../../components/forms/crud-form-component';
import Table from '../../components/table.component';
import { Api } from '../../services/api';
import DefaultHeader from '../../components/header.component';

const getData = async () => {
  return await Api.get('/food');
};

const deleteData = async id => {
  return await Api.delete(`/food/${id}`);
};

function Preparation() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: '_id',
      },
      {
        Header: 'Seção',
        accessor: 'menuSection',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Descrição',
        accessor: 'description',
      },
      {
        Header: 'Tipo',
        accessor: 'foodType',
      },
      {
        Header: 'Complexidade',
        accessor: 'complexity',
      },
      {
        Header: 'Custo',
        accessor: 'cost',
      },
    ],
    [],
  );

  const fetchData = async () => {
    const result = await getData();
    setData(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async id => {
    await deleteData(id);
    await fetchData();
  };

  return (
    <DefaultHeader>
      <div className="w-full mt-5 flex justify-center">
        {!isLoading && <Table columns={columns} data={data} deleteData={handleDelete} />}
      </div>
      <CrudFormComponent handleRefresh={fetchData} />
    </DefaultHeader>
  );
}

export default Preparation;
