import { useEffect, useState, Component } from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { WithContext as ReactTags } from 'react-tag-input';
import { Api } from '../../services/api';
import { getEnumValues } from '../../utils/enumManipulation.utils';
import { titleCase } from '../../utils/stringManipulation.utils';
import ConfirmationModal, { MessageModalInterface } from '../confirmation-modal.component';
import { FoodComponentsComponent } from './food-components-component';
import { RotulosPadraoDasPreparacoesEnum } from '../../objects/sharedEnums';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export interface FoodComponentsInterface {
  name: string;
  quantity: number;
  quantityMetric: string;
  portion: number;
  portionMetric: string;
}

interface FormInputInterface {
  name: string;
  description: string;
  foodType: string;
  complexity: number;
  cost: number;
  menuSection: RotulosPadraoDasPreparacoesEnum;
  characteristcs: string[];
  components: FoodComponentsInterface[];
}

export interface ComponentsFormObjectInterface {
  id: number;
  name: string;
  quantity: number;
  quantityMetric: string;
  portion: number;
  portionMetric: string;
}

export const CrudFormComponent = ({ handleRefresh }) => {
  const [tags, setTags] = useState([] as { id: string; text?: string; className: any }[]);
  const [components, setComponents] = useState([] as number[]);
  const [componentsReturn, setComponentsReturn] = useState([] as ComponentsFormObjectInterface[]);
  const [componentIds, setComponentIds] = useState(0);
  const [formInput, setFormInput] = useState({
    components: [],
    menuSection: getEnumValues(RotulosPadraoDasPreparacoesEnum)[0],
  } as unknown as FormInputInterface);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({} as MessageModalInterface);

  useEffect(() => {
    setFormInput({
      ...formInput,
      components: componentsReturn,
    });
  }, [componentsReturn]);

  useEffect(() => {
    handleInputChange({ target: { name: 'characteristcs', value: tags.map(tag => tag.text), type: 'text' } });
  }, [tags]);

  const handleInputChange = (event: { target: any }) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  function handleAddComponent() {
    const temp = { id: componentIds } as ComponentsFormObjectInterface;
    setComponents([...components, componentIds]);
    setComponentsReturn([...componentsReturn, temp]);
    setComponentIds(componentIds + 1);
  }

  function handleDeleteComponent(i: number) {
    setComponents(
      components.filter((tag, index) => {
        return tag !== i;
      }),
    );

    setComponentsReturn(
      componentsReturn.filter((tag, index) => {
        return tag.id !== i;
      }),
    );
  }

  const handleAddition = (tag: { id: string; text?: string; className: any }) => {
    if (tag.text) {
      tag.text = tag.text.trim().toLowerCase();
    }
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: { id: string; text?: string; className: any }, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formInput);
    Api.post('/food', formInput)
      .then(response => {
        setModalMessage({
          title: 'Sucesso!',
          description: 'Preparação cadastrada com sucesso!',
          buttonText: 'Prosseguir',
          type: 'success',
        });
        setIsOpen(true);
        handleRefresh();
      })
      .catch(error => {
        setModalMessage({
          title: 'Erro..',
          description: `Algo deu errado: ${error}`,
          buttonText: 'Prosseguir',
          type: 'error',
        });
        setIsOpen(true);
      });

    // form.validateFields((err: any, values: any) => {
    //   if (!err) {
    //     onSubmit(values);
    //   }
    // });
  };

  return (
    <div className="flex justify-center">
      <div className="w-fit flex justify-center relative m-5 p-4 border-2 rounded-xl border-teal-200">
        <div className="absolute">
          <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} message={modalMessage} />
        </div>

        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="flex justify-center">
            <div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Nome</label>
                <input
                  className="basic-form-input"
                  id="grid-first-name"
                  name="name"
                  placeholder="Nome da preparação"
                  type="text"
                  value={formInput.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Descrição:</label>
                <input
                  className="basic-form-input "
                  name="description"
                  placeholder="Uma breve descrição"
                  type="text"
                  value={formInput.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full px-3 mb-6 ">
                <label className="basic-form-label">Características:</label>
                <ReactTags
                  classNames={{
                    tagInputField: 'basic-form-input w-[214px]',
                    tag: 'w-fit bg-teal-50 text-gray-700 border border-gray-500 rounded-md mx-1 px-1',
                    tags: 'max-w-[214px]',
                    remove: 'bg-red-200 hover:bg-red-400 rounded-md',
                    activeSuggestion: 'bg-blue-100',
                    selected: 'bg-blue-100',
                    tagInput: 'w-fit',
                    suggestions: 'bg-blue-100',
                    clearAll: 'bg-red-200 hover:bg-red-400 rounded-md',
                    editTagInput: 'w-fit',
                    editTagInputField: 'w-fit',
                  }}
                  tags={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  inputFieldPosition="top"
                  placeholder="Use Enter para cada tag"
                />
              </div>
            </div>

            <div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Tipo de comida:</label>
                <input
                  className="basic-form-input"
                  name="foodType"
                  placeholder="Tipo de comida"
                  type="text"
                  value={formInput.foodType}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Complexidade:</label>
                <input
                  className="basic-form-input"
                  name="complexity"
                  placeholder="1 à 5"
                  type="number"
                  value={formInput.complexity}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Custo:</label>
                <input
                  className="basic-form-input"
                  name="cost"
                  placeholder="1 à 5"
                  type="number"
                  value={formInput.cost}
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-full px-3 mb-6">
                <label className="basic-form-label">Seção do cardápio:</label>
                <select
                  className="basic-form-input"
                  name="menuSection"
                  value={formInput.menuSection}
                  onChange={handleInputChange}
                >
                  {getEnumValues(RotulosPadraoDasPreparacoesEnum).map((value, id) => (
                    <option key={id} value={value}>
                      {titleCase(value)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full px-3 mb-6 ">
            <label className="basic-form-label">Componentes:</label>
            <div className="items-center mt-6 grid grid-cols-3 gap-4">
              {components.map((component, index) => (
                <FoodComponentsComponent
                  key={component}
                  id={component}
                  onDelete={handleDeleteComponent}
                  value={componentsReturn}
                  onChangeValue={setComponentsReturn}
                />
              ))}
            </div>
            <button
              type="button"
              className="justify-end mt-5 max-w-xs shadow bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={handleAddComponent}
            >
              +
            </button>
          </div>
          <div className="flex justify-end ">
            <button
              className="w-36 uppercase shadow bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Gravar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
