import { MultiselectDropDown } from './basics/multiselectDropdown';

interface SelecaoDeCardapiosProps {
  selecaoDeCardapios: string[];
  setSelecaoDeCardapios: (newState) => void;
  opcoes: string[];
}

export function SelecaoDeCardapios({ selecaoDeCardapios, setSelecaoDeCardapios, opcoes }: SelecaoDeCardapiosProps) {
  const onClickSelecionarCardapio = (e, name) => {
    if (selecaoDeCardapios.includes(name)) {
      if (selecaoDeCardapios.length === 1) return;
      setSelecaoDeCardapios(selecaoDeCardapios.filter(cardapio => cardapio !== name));
    } else {
      setSelecaoDeCardapios([...selecaoDeCardapios, name]);
    }
  };

  // Create a multiple select dropdown
  return (
    <MultiselectDropDown
      selecaoDeCardapios={selecaoDeCardapios}
      name="Cardápios"
      options={[
        { name: 'Almoço', onClick: onClickSelecionarCardapio },
        { name: 'Janta', onClick: onClickSelecionarCardapio },
      ]}
    />
  );
}
