import { FaCookieBite } from 'react-icons/fa';
import { TbSalt, TbCookieOff, TbBrandPeanut, TbPig, TbMeat } from 'react-icons/tb';
import { PiCarrot } from 'react-icons/pi';
import { LuLeaf, LuWheatOff, LuMilkOff, LuWheat, LuMilk, LuFlaskConicalOff, LuCandyOff } from 'react-icons/lu';
import { RiSeedlingLine } from 'react-icons/ri';
import { GoNoEntry } from 'react-icons/go';
import { CiWarning, CiApple } from 'react-icons/ci';
import { LiaDrumstickBiteSolid } from 'react-icons/lia';

export enum CaracteristicasComunsEnum {
  SALGADO = 'salgado',
  DOCE = 'doce',
  ORIGEM_ANIMAL = 'origem animal',
  ORIGEM_VEGETAL = 'origem vegetal',
  VEGETARIANO = 'vegetariano',
  VEGANO = 'vegano',
  SEM_GLUTEN = 'sem glúten',
  SEM_LACTOSE = 'sem lactose',
  SEM_ACUCAR = 'sem açúcar',
  SEM_SAL = 'sem sal',
  COM_GLUTEN = 'com glúten',
  COM_LACTOSE = 'com lactose',
  AMENDOIM = 'amendoim',
  CASTANHA = 'castanha',
  LEITE = 'leite',
  ALERGENICO = 'alergênico',
  CONTEM_GLUTEN = 'contém glúten',
  LIGHT = 'light',
  DIET = 'diet',
  ORGANICO = 'orgânico',
  INTEGRAL = 'integral',
  SEM_CONSERVANTES = 'sem conservantes',
  ORIGEM_BOVINA = 'origem bovina',
  ORIGEM_SUINA = 'origem suína',
  ORIGEM_FRANGO = 'origem frango',
  ORIGEM_PEIXE = 'origem peixe',
  ORIGEM_CRUSTACEO = 'origem crustáceo',
}

export const IconesDasCaracteristicas = {
  [CaracteristicasComunsEnum.SALGADO]: <TbSalt />,
  [CaracteristicasComunsEnum.DOCE]: <FaCookieBite />,
  [CaracteristicasComunsEnum.ORIGEM_ANIMAL]: <TbMeat />,
  [CaracteristicasComunsEnum.ORIGEM_VEGETAL]: <LuLeaf />,
  [CaracteristicasComunsEnum.VEGETARIANO]: <PiCarrot />,
  [CaracteristicasComunsEnum.VEGANO]: <RiSeedlingLine />,
  [CaracteristicasComunsEnum.SEM_GLUTEN]: <LuWheatOff />,
  [CaracteristicasComunsEnum.SEM_LACTOSE]: <LuMilkOff />,
  [CaracteristicasComunsEnum.SEM_ACUCAR]: <TbCookieOff />,
  [CaracteristicasComunsEnum.SEM_SAL]: (
    <>
      <GoNoEntry /> <TbSalt />
    </>
  ),
  [CaracteristicasComunsEnum.COM_GLUTEN]: <LuWheat />,
  [CaracteristicasComunsEnum.COM_LACTOSE]: <LuMilk />,
  [CaracteristicasComunsEnum.AMENDOIM]: <TbBrandPeanut />,
  [CaracteristicasComunsEnum.CASTANHA]: <TbBrandPeanut />,
  [CaracteristicasComunsEnum.LEITE]: <LuMilk />,
  [CaracteristicasComunsEnum.ALERGENICO]: <CiWarning />,
  [CaracteristicasComunsEnum.CONTEM_GLUTEN]: <LuWheat />,
  [CaracteristicasComunsEnum.LIGHT]: <CiApple />,
  [CaracteristicasComunsEnum.DIET]: <LuCandyOff />,
  [CaracteristicasComunsEnum.ORGANICO]: <LuLeaf />,
  [CaracteristicasComunsEnum.INTEGRAL]: <LuWheat />,
  [CaracteristicasComunsEnum.SEM_CONSERVANTES]: <LuFlaskConicalOff />,
  [CaracteristicasComunsEnum.ORIGEM_BOVINA]: <TbMeat />,
  [CaracteristicasComunsEnum.ORIGEM_SUINA]: <TbPig />,
  [CaracteristicasComunsEnum.ORIGEM_FRANGO]: <LiaDrumstickBiteSolid />,
};
