import { createContext, useContext, useState } from 'react';
import { CardapioMensal, ICardapioMensalConstrutorArgs } from '../../objects/cardapio-mensal';
import { RotulosPadraoDasPreparacoesEnum } from '../../objects/sharedEnums';
import { CaracteristicasComunsEnum } from '../../enums/alimento.enum';

type ContextType = {
  cardapioMensal: CardapioMensal;
  carregarCardapioMensal: (obj: ICardapioMensalConstrutorArgs) => void;
  bancoDeAlimentos: BancoDeAlimentos;
};

type FoodItem = {
  name: string;
  value: string;
};

type BancoDeAlimentos = {
  [RotulosPadraoDasPreparacoesEnum.ENTRADA]: FoodItem[];
  [RotulosPadraoDasPreparacoesEnum.PRATO_PRINCIPAL]: FoodItem[];
  [RotulosPadraoDasPreparacoesEnum.PRATO_VEGANO]: FoodItem[];
  [RotulosPadraoDasPreparacoesEnum.GUARNICAO]: FoodItem[];
  [RotulosPadraoDasPreparacoesEnum.ACOMPANHAMENTO]: FoodItem[];
  [RotulosPadraoDasPreparacoesEnum.SOBREMESA]: FoodItem[];
};

export const ContextoGlobal = createContext({} as ContextType);

export const AppContext = ({ children }) => {
  const [cardapioMensal, setCardapioMensal] = useState<CardapioMensal>(
    new CardapioMensal({ nomeArquivo: 'cardapio-mensal.json', versao: 1 }),
  );

  const carregarCardapioMensal = (obj: ICardapioMensalConstrutorArgs) => {
    setCardapioMensal(new CardapioMensal(obj));
  };

  const bancoDeAlimentos = {
    [RotulosPadraoDasPreparacoesEnum.ENTRADA]: [
      {
        name: 'Maçã',
        value: 'maçã',
      },
      {
        name: 'Laranja',
        value: 'laranja',
      },
      {
        name: 'Banana',
        value: 'banana',
      },
      {
        name: 'Uva',
        value: 'uva',
      },
      {
        name: 'Maracujá',
        value: 'maracuja',
      },
      {
        name: 'Melancia',
        value: 'melancia',
      },
      {
        name: 'Abacate',
        value: 'abacate',
      },
    ],
    [RotulosPadraoDasPreparacoesEnum.PRATO_PRINCIPAL]: [
      {
        name: 'Arroz',
        value: 'arroz',
        caracteristicas: ['integral', 'branco', 'parboilizado', 'cateto', 'arbóreo', 'carnaroli', 'japonês'],
      },
      {
        name: 'Feijão',
        value: 'feijao',
      },
      {
        name: 'Macarrão',
        value: 'macarrao',
      },
      {
        name: 'Carne',
        value: 'carne',
      },
      {
        name: 'Frango',
        value: 'frango',
      },
      {
        name: 'Peixe',
        value: 'peixe',
      },
      {
        name: 'Ovo',
        value: 'ovo',
      },
    ],
    [RotulosPadraoDasPreparacoesEnum.PRATO_VEGANO]: [
      {
        name: 'Arroz',
        value: 'arroz',
      },
      {
        name: 'Feijão',
        value: 'feijao',
      },
      {
        name: 'Macarrão',
        value: 'macarrao',
      },
      {
        name: 'Soja',
        value: 'soja',
      },
      {
        name: 'Grão de bico',
        value: 'graodebico',
        caracteristicas: [
          CaracteristicasComunsEnum.ORGANICO,
          CaracteristicasComunsEnum.VEGANO,
          CaracteristicasComunsEnum.INTEGRAL,
          CaracteristicasComunsEnum.ORIGEM_VEGETAL,
        ],
      },
      {
        name: 'Lentilha',
        value: 'lentilha',
      },
      {
        name: 'Tofu',
        value: 'tofu',
      },
    ],
    [RotulosPadraoDasPreparacoesEnum.GUARNICAO]: [
      {
        name: 'Batata frita',
        value: 'batatafrita',
      },
      {
        name: 'Farofa',
        value: 'farofa',
      },
      {
        name: 'Salada',
        value: 'salada',
      },
      {
        name: 'Legumes',
        value: 'legumes',
      },
      {
        name: 'Purê',
        value: 'pure',
      },
      {
        name: 'Grão de bico',
        value: 'graodebico',
      },
      {
        name: 'Lentilha',
        value: 'lentilha',
      },
    ],
    [RotulosPadraoDasPreparacoesEnum.ACOMPANHAMENTO]: [
      {
        name: 'Pão',
        value: 'pao',
      },
      {
        name: 'Manteiga',
        value: 'manteiga',
      },
      {
        name: 'Requeijão',
        value: 'requeijao',
      },
      {
        name: 'Geleia',
        value: 'geleia',
      },
      {
        name: 'Mel',
        value: 'mel',
      },
      {
        name: 'Açucar',
        value: 'acucar',
      },
      {
        name: 'Adoçante',
        value: 'adocante',
      },
    ],
    [RotulosPadraoDasPreparacoesEnum.SOBREMESA]: [
      {
        name: 'Pudim',
        value: 'pudim',
      },
      {
        name: 'Sorvete',
        value: 'sorvete',
      },
      {
        name: 'Gelatina',
        value: 'gelatina',
      },
      {
        name: 'Bolo',
        value: 'bolo',
      },
      {
        name: 'Doce',
        value: 'doce',
      },
      {
        name: 'Fruta',
        value: 'fruta',
      },
      {
        name: 'Chocolate',
        value: 'chocolate',
      },
    ],
  };

  return (
    <ContextoGlobal.Provider value={{ cardapioMensal, carregarCardapioMensal, bancoDeAlimentos }}>
      {children}
    </ContextoGlobal.Provider>
  );
};

export const useContextoGlobal = () => {
  return useContext(ContextoGlobal);
};
