import { Alimento, IAlimentoConstrutorArgs } from './alimento';

export interface IPreparacaoConstrutorArgs {
  rotulo: string;
  anotacao?: string;
  alimento?: Alimento | IAlimentoConstrutorArgs;
}

export class Preparacao {
  private _rotulo: string;
  private _anotacao?: string;
  private _alimento?: Alimento;

  constructor({ rotulo, anotacao, alimento }: IPreparacaoConstrutorArgs) {
    this._rotulo = rotulo;
    this._anotacao = anotacao;
    this._alimento = alimento as Alimento | undefined;
  }

  public static copiar(preparacao: Preparacao): IPreparacaoConstrutorArgs {
    let alimento;
    if (preparacao.alimento) {
      alimento = Alimento.copiar(preparacao.alimento);
    }

    return {
      rotulo: preparacao.rotulo,
      anotacao: preparacao.anotacao,
      alimento,
    };
  }

  public static colar(args: IPreparacaoConstrutorArgs): Preparacao {
    let alimento;
    if (args.alimento) {
      alimento = Alimento.colar(args.alimento as IAlimentoConstrutorArgs);
    }

    return new Preparacao({
      rotulo: args.rotulo,
      anotacao: args.anotacao,
      alimento,
    });
  }

  public atualizarNomeAlimento(nome: string) {
    if (this.alimento) {
      this.alimento.nome = nome;
    } else {
      this.alimento = new Alimento({
        nome,
        descricao: '',
        tipo: '',
        complexidade: 0,
        custo: 0,
        cor: 0,
        caracteristicas: [],
      });
    }
  }

  // Getters e setters

  /**
   * Getter rotulo
   * @return {string}
   */
  public get rotulo(): string {
    return this._rotulo;
  }

  /**
   * Getter anotacao
   * @return {string}
   */
  public get anotacao(): string | undefined {
    return this._anotacao;
  }

  /**
   * Setter rotulo
   * @param {string} value
   */
  public set rotulo(value: string) {
    this._rotulo = value;
  }

  /**
   * Setter anotacao
   * @param {string | undefined} value
   */
  public set anotacao(value: string | undefined) {
    this._anotacao = value;
  }

  /**
   * Getter alimento
   * @return {Alimento}
   */
  public get alimento(): Alimento | undefined {
    return this._alimento;
  }

  /**
   * Setter alimento
   * @param {Alimento | undefined} value
   */
  public set alimento(value: Alimento | undefined) {
    this._alimento = value;
  }
}
