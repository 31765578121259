enum MesParaData {
  Janeiro = 0,
  Fevereiro = 1,
  Marco = 2,
  Abril = 3,
  Maio = 4,
  Junho = 5,
  Julho = 6,
  Agosto = 7,
  Setembro = 8,
  Outubro = 9,
  Novembro = 10,
  Dezembro = 11,
}

const dayToName = (day: number) => {
  switch (day) {
    case 0:
      return 'DOM';
    case 1:
      return 'SEG';
    case 2:
      return 'TER';
    case 3:
      return 'QUA';
    case 4:
      return 'QUI';
    case 5:
      return 'SEX';
    case 6:
      return 'SAB';
    default:
      return 'Unknown';
  }
};

export const IsoToDayMonth = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return `${day}/${date.getUTCMonth() + 1}`;
};

export const IsoToDay = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return `${day}`;
};

export const IsoToDayWithName = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  console.log(isoDate, date);
  return { day, name: dayToName(date.getUTCDay()) };
};

export const PrimeiraSegundaDoMes = (date: Date): Date => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const day = firstDay.getDay();
  const firstMonday = new Date(firstDay.setDate(firstDay.getDate() + (day === 0 ? 1 : 8 - day)));
  return firstMonday;
};

export enum MesParaDataEnum {
  Janeiro = 0,
  Fevereiro = 1,
  Marco = 2,
  Abril = 3,
  Maio = 4,
  Junho = 5,
  Julho = 6,
  Agosto = 7,
  Setembro = 8,
  Outubro = 9,
  Novembro = 10,
  Dezembro = 11,
}

/**
 * Pega a data do primeiro dia do mês escolhido. Caso seja passado um mês anterior ao atual, pegamos a data do ano seguinte.
 * @param data
 */
export const DataParaMes = (data: Date, mesDesejado: MesParaDataEnum) => {
  if (data.getMonth() > mesDesejado) {
    return new Date(data.getFullYear() + 1, mesDesejado, 1);
  }
  return new Date(data.getFullYear(), mesDesejado, 1);
};

export const MesSeguinte = (mes: number) => {
  if (mes === 11) {
    return 0;
  }
  return mes + 1;
};
