import { useState } from 'react';
import { useContextoGlobal } from '../hooks/contexts/contextoGlobal';
import { Menu } from '../objects/menu';
import { Cell } from './cell.component';

interface CellColProps {
  menu: Menu;
  updateCell: (value: string) => void;
}

export function CellCol({ menu, updateCell }: CellColProps) {
  const { bancoDeAlimentos } = useContextoGlobal();
  return (
    <div className="w-fit h-fit flex flex-col space-y-1">
      {menu.preparacoes.map((preparacao, index) => {
        const [nomeAlimento, setNomeAlimento] = useState(preparacao.alimento?.nome || '');
        return (
          <Cell
            value={nomeAlimento}
            updateCell={nome => {
              preparacao.atualizarNomeAlimento(nome);
              setNomeAlimento(nome);
            }}
            optionsData={bancoDeAlimentos[preparacao.rotulo]}
          />
        );
      })}
    </div>
  );
}
