import { IPreparacaoConstrutorArgs, Preparacao } from './preparacao';

export interface IMenuConstrutorArgs {
  rotulo: string;
  preparacoes: IPreparacaoConstrutorArgs[];
}

export class Menu {
  private _rotulo: string;
  private _preparacoes: Preparacao[];

  constructor({ rotulo, preparacoes }: IMenuConstrutorArgs) {
    if (preparacoes.length !== 6) {
      throw new Error('Um menu precisa de exatamente 6 preparações');
    }

    this._rotulo = rotulo;
    this._preparacoes = preparacoes.map(preparacao => new Preparacao(preparacao));
  }

  public static copiar(menu: Menu): IMenuConstrutorArgs {
    return {
      rotulo: menu.rotulo,
      preparacoes: menu.preparacoes.map(preparacao => Preparacao.copiar(preparacao)),
    };
  }

  public static colar(args: IMenuConstrutorArgs): Menu {
    return new Menu(args);
  }

  // Getters e setters

  /**
   * Getter rotulo
   * @return {string}
   */
  public get rotulo(): string {
    return this._rotulo;
  }

  /**
   * Getter preparacoes
   * @return {Preparacao[]}
   */
  public get preparacoes(): Preparacao[] {
    return this._preparacoes;
  }

  /**
   * Setter rotulo
   * @param {string} value
   */
  public set rotulo(value: string) {
    this._rotulo = value;
  }

  /**
   * Setter preparacoes
   * @param {Preparacao[]} value
   */
  public set preparacoes(value: Preparacao[]) {
    if (value.length !== 6) {
      throw new Error('Um menu precisa de exatamente 6 preparações');
    }
    this._preparacoes = value;
  }
}
