export interface IAlimentoConstrutorArgs {
  nome: string;
  descricao: string;
  tipo: string;
  complexidade: number;
  custo: number;
  cor: number;
  caracteristicas: string[];
}

export class Alimento {
  private _nome: string;
  private _descricao: string;
  private _tipo: string;
  private _complexidade: number;
  private _custo: number;
  private _cor: number;
  private _caracteristicas: string[];

  constructor({ nome, descricao, tipo, complexidade, custo, cor, caracteristicas }: IAlimentoConstrutorArgs) {
    this._nome = nome;
    this._descricao = descricao;
    this._tipo = tipo;
    this._complexidade = complexidade;
    this._custo = custo;
    this._cor = cor;
    this._caracteristicas = caracteristicas;
  }

  public static copiar(alimento: Alimento): IAlimentoConstrutorArgs {
    return {
      nome: alimento.nome,
      descricao: alimento.descricao,
      tipo: alimento.tipo,
      complexidade: alimento.complexidade,
      custo: alimento.custo,
      cor: alimento.cor,
      caracteristicas: alimento.caracteristicas,
    };
  }

  public static colar(args: IAlimentoConstrutorArgs): Alimento {
    return new Alimento(args);
  }

  public atualizar(args: IAlimentoConstrutorArgs) {
    this.nome = args.nome;
    this.descricao = args.descricao;
    this.tipo = args.tipo;
    this.complexidade = args.complexidade;
    this.custo = args.custo;
    this.cor = args.cor;
    this.caracteristicas = args.caracteristicas;

    return this.toObject();
  }

  public toObject() {
    return {
      nome: this.nome,
      descricao: this.descricao,
      tipo: this.tipo,
      complexidade: this.complexidade,
      custo: this.custo,
      cor: this.cor,
      caracteristicas: this.caracteristicas,
    };
  }

  // Getters e setters

  /**
   * Getter nome
   * @return {string}
   */
  public get nome(): string {
    return this._nome;
  }

  /**
   * Getter descricao
   * @return {string}
   */
  public get descricao(): string {
    return this._descricao;
  }

  /**
   * Getter tipo
   * @return {string}
   */
  public get tipo(): string {
    return this._tipo;
  }

  /**
   * Getter complexidade
   * @return {number}
   */
  public get complexidade(): number {
    return this._complexidade;
  }

  /**
   * Getter custo
   * @return {number}
   */
  public get custo(): number {
    return this._custo;
  }

  /**
   * Getter cor
   * @return {number}
   */
  public get cor(): number {
    return this._cor;
  }

  /**
   * Getter caracteristicas
   * @return {string[]}
   */
  public get caracteristicas(): string[] {
    return this._caracteristicas;
  }

  /**
   * Setter nome
   * @param {string} value
   */
  public set nome(value: string) {
    if (value.length > 0) {
      this._nome = value;
    } else {
      this._nome = '';
    }
  }

  /**
   * Setter descricao
   * @param {string} value
   */
  public set descricao(value: string) {
    this._descricao = value;
  }

  /**
   * Setter tipo
   * @param {string} value
   */
  public set tipo(value: string) {
    this._tipo = value;
  }

  /**
   * Setter complexidade
   * @param {number} value
   */
  public set complexidade(value: number) {
    this._complexidade = value;
  }

  /**
   * Setter custo
   * @param {number} value
   */
  public set custo(value: number) {
    this._custo = value;
  }

  /**
   * Setter cor
   * @param {number} value
   */
  public set cor(value: number) {
    this._cor = value;
  }

  /**
   * Setter caracteristicas
   * @param {string[]} value
   */
  public set caracteristicas(value: string[]) {
    this._caracteristicas = value;
  }
}
