import { useEffect, useState } from 'react';
import ReactPDF, { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import { MyDocument } from './PDF/pdf-menu';
import { DropDown } from './basics/dropdown';
import { BsFiletypePdf } from 'react-icons/bs';
import { BiSave, BiUpload } from 'react-icons/bi';
import { Cardapio } from './cardapio';
import { SelecaoDeCardapios } from './selecaoDeCardapios';
import { CardapioMensal, ICardapioMensalConstrutorArgs } from '../objects/cardapio-mensal';
import { useContextoGlobal } from '../hooks/contexts/contextoGlobal';
// Food options for the cell
const CELL_DATA = [
  {
    name: 'Maçã',
    value: 'maçã',
  },
  {
    name: 'Laranja',
    value: 'laranja',
  },
  {
    name: 'Banana',
    value: 'banana',
  },
  {
    name: 'Uva',
    value: 'uva',
  },
  {
    name: 'Maracujá',
    value: 'maracuja',
  },
  {
    name: 'Melancia',
    value: 'melancia',
  },
  {
    name: 'Abacate',
    value: 'abacate',
  },
];

export function Menu({ bancoDeAlimentos }) {
  const [selecaoDeCardapios, setSelecaoDeCardapios] = useState(['Almoço']);
  const { cardapioMensal, carregarCardapioMensal } = useContextoGlobal();
  const [baixarPDF, setBaixarPDF] = useState(false);
  let inputFileRef;
  const [semanaEscolhida, setSemanaEscolhida] = useState(0);
  const [instance, updateInstance] = usePDF({
    document: (
      <MyDocument
        cardapioSemanal={cardapioMensal.cardapiosSemanais[semanaEscolhida]}
        textos={cardapioMensal.getTextos(semanaEscolhida)}
      />
    ),
  });

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(CardapioMensal.copiar(cardapioMensal))], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'menu.json';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleOpenFile = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const text = e.target?.result;
      let obj = {} as ICardapioMensalConstrutorArgs;
      if (text) {
        obj = JSON.parse(text as string);
      }
      carregarCardapioMensal(obj);
    };
    reader.readAsText(file);
  };

  const onBtnClickToOpenFile = () => {
    inputFileRef?.click();
  };

  const pdfDownloadButtonClick = () => {
    updateInstance(
      <MyDocument
        cardapioSemanal={cardapioMensal.cardapiosSemanais[semanaEscolhida]}
        textos={cardapioMensal.getTextos(semanaEscolhida)}
      />,
    );
    setBaixarPDF(true);
  };

  useEffect(() => {
    if (instance.blob && !instance.loading && baixarPDF) {
      const url = URL.createObjectURL(instance.blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'menuBlob.pdf';
      a.click();
      URL.revokeObjectURL(url);
      setBaixarPDF(false);
    }
  }, [instance, baixarPDF]);

  const dropDownData = [
    { name: 'Salvar', icon: BiSave, onClick: handleDownload },
    { name: 'Carregar', icon: BiUpload, onClick: onBtnClickToOpenFile },
    { name: 'Exportar para PDF', icon: BsFiletypePdf, onClick: pdfDownloadButtonClick },
  ];

  const mudarSemana = (distancia: number) => {
    const numeroSemanas = cardapioMensal.cardapiosSemanais.length;
    const novaSemana = semanaEscolhida + distancia;
    // Verifica se a semana é válida
    if (novaSemana < 0 || novaSemana >= numeroSemanas) return;
    setSemanaEscolhida(novaSemana);
  };

  return (
    <>
      <input ref={input => (inputFileRef = input)} type="file" onChange={handleOpenFile} hidden={true} />
      <div className=" w-full h-full flex flex-col p-6 mt-4 space-y-1 items-center">
        <span className="text-xl font-bold">
          Cardápio Mensal - {cardapioMensal.nomeDoMes()} - {cardapioMensal.nomeDoAno()}
        </span>
        {cardapioMensal && (
          <div>
            <div className="space-x-2 mb-3">
              <DropDown name="Menu" options={dropDownData} />
              <SelecaoDeCardapios
                selecaoDeCardapios={selecaoDeCardapios}
                setSelecaoDeCardapios={setSelecaoDeCardapios}
                opcoes={['Almoço', 'Janta']}
              />
            </div>

            <div className="flex flex-col space-y-2">
              {selecaoDeCardapios.includes('Almoço') && (
                <Cardapio
                  bancoDeAlimentos={bancoDeAlimentos}
                  cardapioSemanal={cardapioMensal.cardapiosSemanais[semanaEscolhida]}
                  indiceDaRefeicao={0}
                  mudarSemana={mudarSemana}
                />
              )}
              {selecaoDeCardapios.includes('Janta') && (
                <Cardapio
                  bancoDeAlimentos={bancoDeAlimentos}
                  cardapioSemanal={cardapioMensal.cardapiosSemanais[semanaEscolhida]}
                  indiceDaRefeicao={1}
                  mudarSemana={mudarSemana}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
