import DefaultHeader from '../../components/header.component';
import { Link, useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();

  const goTo = path => {
    console.log(path);
    navigate(path);
  };

  return (
    <div className="App">
      <DefaultHeader>
        <div className="w-full h-4/6 max-w-[1920px] align-middle flex flex-col justify-center items-center ">
          <div className="grid gap-96 grid-cols-2">
            <button
              onClick={() => goTo('/menu')}
              className="mx-4 basic-button w-60 flex justify-center items-center text-center"
            >
              Novo Cardápio
            </button>
            <button
              onClick={() => goTo('/prep')}
              className="mx-4 basic-button w-60 flex justify-center items-center text-center"
            >
              Cadastro de preparações
            </button>
          </div>
        </div>
      </DefaultHeader>
    </div>
  );
}

export default App;
