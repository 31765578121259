import { useNavigate } from 'react-router-dom';
import logo from '../imgs/logo_ru.png';
import { AiOutlineArrowLeft } from 'react-icons/ai';

function DefaultHeader({ children }) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-screen h-screen max-h-screen">
      <div className="w-full h-32 bg-emerald-500 flex items-center">
        <div className="w-full flex items-center justify-center">
          <img className="w-32 h-32 py-1 ml-10" src={logo} />
          <p className="ml-10 text-2xl font-semibold text-gray-800">Sistema de gestão de cardápios acadêmicos v0.01</p>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center">
        <div className="w-full max-w-[1440px] mt-4 flex flex-col items-center">
          <button onClick={goBack} className="basic-button text-zinc-800 hover:text-black w-16 h-12 px-0 self-start">
            <AiOutlineArrowLeft className="h-full w-full self-start" />
          </button>
        </div>

        {children}
      </div>
    </div>
  );
}

export default DefaultHeader;
