import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BiChevronDown } from 'react-icons/bi';
import { IconType } from 'react-icons/lib';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface OptionsObject {
  name: string;
  icon?: IconType;
  onClick?: (e, ...args) => void;
}

interface DropDownProps {
  name: string;
  options: OptionsObject[];
}

export const DropDown = ({ name, options }: DropDownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {name}
          <BiChevronDown className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option, index) => (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={e => {
                      if (option.onClick) {
                        option.onClick(e, option.name);
                      }
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex flex-row space-x-10 px-4 py-2 text-sm w-full',
                    )}
                  >
                    {option.icon ? <option.icon className="h-5 w-5 mr-3" /> : null}
                    {` ${option.name}`}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
