import { CardapioDiario, ICardapioDiarioConstrutorArgs } from './cardapio-diario';
import { RotulosPadraoDasPreparacoesEnum, RotulosPadraoDosMenusEnum } from './sharedEnums';

const ArrayPadraoDeRotuloDasPreparacoes = [
  RotulosPadraoDasPreparacoesEnum.ENTRADA,
  RotulosPadraoDasPreparacoesEnum.PRATO_PRINCIPAL,
  RotulosPadraoDasPreparacoesEnum.PRATO_VEGANO,
  RotulosPadraoDasPreparacoesEnum.GUARNICAO,
  RotulosPadraoDasPreparacoesEnum.ACOMPANHAMENTO,
  RotulosPadraoDasPreparacoesEnum.SOBREMESA,
];

const PreparacoesBase = ArrayPadraoDeRotuloDasPreparacoes.map(rotulo => ({ rotulo }));

export interface ICardapioSemanalConstrutorArgs {
  versao?: number;
  dataInicial?: Date;
  dias?: ICardapioDiarioConstrutorArgs[];
  aprovado?: boolean;
  rotuloAlmoco?: string;
  rotuloJantar?: string;
  rotulosDasPreparacoes?: string[];
}

export class CardapioSemanal {
  private _aprovado: boolean;
  private _dataInicial: Date;
  private versao: number;
  private _rotuloAlmoco: string;
  private _rotuloJantar: string;
  private _rotulosDasPreparacoes: string[];
  private _dias: CardapioDiario[];

  constructor({
    dias,
    aprovado,
    dataInicial,
    versao,
    rotuloAlmoco,
    rotuloJantar,
    rotulosDasPreparacoes,
  }: ICardapioSemanalConstrutorArgs) {
    this._aprovado = aprovado || false;
    this.versao = versao || 1;
    this._rotuloAlmoco = rotuloAlmoco || RotulosPadraoDosMenusEnum.ALMOCO;
    this._rotuloJantar = rotuloJantar || RotulosPadraoDosMenusEnum.JANTAR;
    this._rotulosDasPreparacoes = rotulosDasPreparacoes || ArrayPadraoDeRotuloDasPreparacoes;

    const dataEInstanciaDeDate = dataInicial instanceof Date;
    if (dataInicial && !dataEInstanciaDeDate) {
      dataInicial = new Date(dataInicial);
    }

    if (dias) {
      this._dias = dias.map(dia => new CardapioDiario(dia));
    } else {
      // Caso o usuário não tenha passado os dados dos dias, vamos criar os dias automaticamente com as preparações vazias
      this._dias = this.gerarDiasVazios(dataInicial);
    }

    if (dataInicial) {
      this._dataInicial = dataInicial;
    } else {
      this._dataInicial = this._dias[0].data;
    }
  }

  private gerarDiasVazios(dataInicial?: Date): CardapioDiario[] {
    // A data inicial para criar as semanas pode ser passada como argumento ou será a data atual
    const tempDias: CardapioDiario[] = [];
    dataInicial = dataInicial || new Date();
    // Passar horário para 12h
    dataInicial.setHours(12, 0, 0, 0);
    const umDiaEmMilisegundos = 24 * 60 * 60 * 1000;
    for (let i = 0; i < 7; i++) {
      const iDiasEmMilisegundos = i * umDiaEmMilisegundos;
      tempDias.push(
        new CardapioDiario({
          data: new Date(dataInicial.getTime() + iDiasEmMilisegundos),
          menus: [
            {
              rotulo: this._rotuloAlmoco,
              preparacoes: PreparacoesBase,
            },
            {
              rotulo: this._rotuloJantar,
              preparacoes: PreparacoesBase,
            },
          ],
        }),
      );
    }
    return tempDias;
  }

  public static copiar(semana: CardapioSemanal): ICardapioSemanalConstrutorArgs {
    return {
      aprovado: semana.aprovado,
      dataInicial: semana.dataInicial,
      versao: semana.versao,
      rotuloAlmoco: semana.rotuloAlmoco,
      rotuloJantar: semana.rotuloJantar,
      rotulosDasPreparacoes: semana.rotulosDasPreparacoes,
      dias: semana.dias.map(dia => CardapioDiario.copiar(dia)),
    };
  }

  public static colar(args: ICardapioSemanalConstrutorArgs): CardapioSemanal {
    return new CardapioSemanal(args);
  }

  public aprovar(): void {
    this._aprovado = true;
  }

  public arrayDePreparacoesPorRotulo(isAlmoco: boolean = true): { [key: string]: string[] } {
    const preparacoes = {} as { [key: string]: string[] };
    this._dias.forEach(dia => {
      const indiceDoMenu = isAlmoco ? 0 : 1;
      dia.menus[indiceDoMenu].preparacoes.forEach(preparacao => {
        if (!preparacoes[preparacao.rotulo]) {
          preparacoes[preparacao.rotulo] = [];
        }
        preparacoes[preparacao.rotulo].push(preparacao.alimento?.nome || '');
      });
    });
    return preparacoes;
  }

  // Getters e setters

  /**
   * Getter aprovado
   * @return {boolean}
   */
  public get aprovado(): boolean {
    return this._aprovado;
  }

  /**
   * Getter dataInicial
   * @return {Date}
   */
  public get dataInicial(): Date {
    return this._dataInicial;
  }

  /**
   * Getter $versao
   * @return {number}
   */
  public get $versao(): number {
    return this.versao;
  }

  /**
   * Getter rotuloAlmoco
   * @return {string}
   */
  public get rotuloAlmoco(): string {
    return this._rotuloAlmoco;
  }

  /**
   * Getter rotuloJantar
   * @return {string}
   */
  public get rotuloJantar(): string {
    return this._rotuloJantar;
  }

  /**
   * Getter rotulosDasPreparacoes
   * @return {string[]}
   */
  public get rotulosDasPreparacoes(): string[] {
    return this._rotulosDasPreparacoes;
  }

  /**
   * Getter dias
   * @return {CardapioDiario[]}
   */
  public get dias(): CardapioDiario[] {
    return this._dias;
  }

  /**
   * Setter aprovado
   * @param {boolean} value
   */
  public set aprovado(value: boolean) {
    this._aprovado = value;
  }

  /**
   * Setter dataInicial
   * @param {Date} value
   */
  public set dataInicial(value: Date) {
    this._dataInicial = value;
  }

  /**
   * Setter $versao
   * @param {number} value
   */
  public set $versao(value: number) {
    this.versao = value;
  }

  /**
   * Setter rotuloAlmoco
   * @param {string} value
   */
  public set rotuloAlmoco(value: string) {
    this._rotuloAlmoco = value;
  }

  /**
   * Setter rotuloJantar
   * @param {string} value
   */
  public set rotuloJantar(value: string) {
    this._rotuloJantar = value;
  }

  /**
   * Setter rotulosDasPreparacoes
   * @param {string[]} value
   */
  public set rotulosDasPreparacoes(value: string[]) {
    this._rotulosDasPreparacoes = value;
  }

  /**
   * Setter dias
   * @param {CardapioDiario[]} value
   */
  public set dias(value: CardapioDiario[]) {
    this._dias = value;
  }
}
