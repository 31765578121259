//TODO: Pegar isso do backend
export enum RotulosPadraoDasPreparacoesEnum {
  ENTRADA = 'entrada',
  PRATO_PRINCIPAL = 'prato principal',
  PRATO_VEGANO = 'prato vegano',
  GUARNICAO = 'guarnição',
  ACOMPANHAMENTO = 'acompanhamento',
  SOBREMESA = 'sobremesa',
}

export enum RotulosPadraoDosMenusEnum {
  ALMOCO = 'Almoço',
  JANTAR = 'Jantar',
}
