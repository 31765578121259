import { useState } from 'react';

interface CellProps {
  optionsData: any[];
  value?: string;
  updateCell: (value: string) => void;
}

export function Cell({ optionsData, value, updateCell }: CellProps) {
  // Autocomplete text with the food options
  const [text, setText] = useState('');
  // The selected food
  const [selected, setSelected] = useState(null);
  // The list of food options
  const [options, setOptions] = useState(optionsData);

  const [focus, setFocus] = useState(false);

  const enableFocus = e => {
    setFocus(true);
  };

  const disableFocus = e => {
    if (e?.relatedTarget?.nodeName !== 'BUTTON') {
      setFocus(false);
    }
  };

  // When the text changes, filter the options
  const onTextChange = e => {
    console.log(options);
    const text = e.target.value;
    updateCell(text);
    setOptions(optionsData?.filter(option => option.name.toLowerCase().includes(text.toLowerCase())));
  };

  // When an option is selected, set the selected food
  const onOptionSelected = option => {
    setSelected(option.name);
    updateCell(option.name);
    setOptions([]);
  };

  return (
    <div className="w-40 h-28 relative" onFocus={enableFocus}>
      <textarea
        onBlur={disableFocus}
        className="w-full h-full text-center bg-slate-50 break-all resize-none pt-2"
        value={value || ''}
        onChange={onTextChange}
      />
      {options?.length > 0 && focus && (
        <div className="w-full max-h-52 overflow-y-auto flex flex-col bg-slate-50 border-2 border-slate-200 rounded-md  absolute -top-3 -right-full z-10">
          {options.map(option => (
            <button
              className="hover:bg-slate-300 p-1 cursor-pointer text-left leading-tight"
              key={option.value}
              onClick={() => onOptionSelected(option)}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
